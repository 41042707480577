import React from "react";
import { useTicketEscalationLog } from "../../state/api/tickets/useTicketEscalationLog";
import { ITicket, TicketEscalationLogStatus, TicketEscalationSource } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import Card from "../card/Card";
import { formatDateGmailStyle } from "../../util/formatter";
import TicketEscalationActionChip from "./TicketEscalationActionChip";
import InfiniteScroll from "../pagination/InfiniteScroll";
import EscalateTicketManuallyButton from "./EscalateTicketManuallyButton";
import Icon from "../icons/Icon";

export default function TicketEscalationLog({ticket}: {ticket: ITicket}) {
    const { loadingTicketEscalationLog, reloadTicketEscalationLog, ticketEscalationLog } = useTicketEscalationLog(ticket);

    if (loadingTicketEscalationLog) return <LoadingSpinner />

    return (
        <Flex fullWidth>
            <Flex fullWidth>
                <Flex wrap row justify="between" fullWidth>
                    <strong>Vergangene Eskalationen</strong>
                    <Flex className="ms-auto">
                        <EscalateTicketManuallyButton ticket={ticket} />
                    </Flex>
                </Flex>
            </Flex>
            {

                loadingTicketEscalationLog 
                ? <LoadingSpinner />
                : (
                    <InfiniteScroll>
                        {
                            ticketEscalationLog && !!ticketEscalationLog.length 
                            ? ticketEscalationLog.map(d => (
                                <Card 
                                    key={d._id}
                                    color={d.status === TicketEscalationLogStatus.Success ? "bright" : "error"}
                                    header={
                                        <Flex row className="w-100" justify="between">
                                            <Flex row>
                                                {
                                                    d.source === TicketEscalationSource.System
                                                    ? <Icon icon="computer" />
                                                    : <Icon icon="person-fill" />
                                                }
                                                <strong>{formatDateGmailStyle(d.createdAt)}</strong>
                                            </Flex>
                                            <Typography bold basedOnThisBackground={d.status === TicketEscalationLogStatus.Success ? "bright" : "error"}>
                                                { d.status === TicketEscalationLogStatus.Success ? "Eskalation erfolgreich" : "Eskalation fehlgeschlagen" }
                                            </Typography>
                                        </Flex>
                                    }    
                                >
                                    <Flex className="w-100">
                                        <Typography bold>Aktionen</Typography>
                                        <Flex row wrap className="w-100">
                                            {
                                                d.escalationWorkflow && d.escalationWorkflow.actions && d.escalationWorkflow.actions.length 
                                                ? d.escalationWorkflow.actions.map(a => (
                                                    <TicketEscalationActionChip action={a.action} canBeSelected isActive readOnly key={a.action} />
                                                ))
                                                : <span>Keine Aktionen hinterlegt</span>
                                            }
                                        </Flex>
                                    </Flex>
                                    {
                                        d.status !== TicketEscalationLogStatus.Success && d.message && (
                                            <>
                                                <Typography bold>Nachricht</Typography>
                                                <Flex row className="w-100" justify="between">
                                                    <Typography truncate={false} wrap >{d.message}</Typography>
                                                </Flex>
                                            </>
                                        )
                                    }
                                </Card>
                            ))
                            : <em>Noch keine Eskalationen</em>
                        }
                    </InfiniteScroll>
                )
            }
        </Flex>
    )
}