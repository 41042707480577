import React from "react";
import useUserUtil from "../../hooks/useUserUtil";
import { useRoles } from "../../state/api/roles/useRoles";
import { DatevDuplicateReason, DatevStatus, DbId, IUser } from "../../types/ApiTypes";
import { IDatevEmployee } from "../../types/employee.schema";
import Flex from "../container/Flex";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import Pill from "../pills/Pill";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import UserRoleIndicator from "../user/RoleBanner";
import UserUpdateForm from "../user/UserUpdateForm";
import ManageEmployeeAbsenceButton from "./ManageEmployeeAbsenceButton";
import useLabels, { Label } from "../../hooks/useLabels";
import Tooltip from "../tooltip/Tooltip";
import Icon from "../icons/Icon";

export interface IEmployeeListItemProps {
  employee?: IUser,
  datevEmployee?: IDatevEmployee,
  isMissingMail?: boolean
}

export default function EmployeeListItem(props: IEmployeeListItemProps) {

  const {
    employee,
    datevEmployee,
    isMissingMail = false
  } = props;

  const {
    getName,
    getCurrentTenantAuthority
  } = useUserUtil();

  const { roles } = useRoles();
  const getLabel = useLabels();

  const availableRoles = roles && !!roles.length && roles.filter(r => !r.isClient);

  if (!availableRoles) return null;

  availableRoles.sort((a, b) => a.accessLevel - b.accessLevel);

  const authority = getCurrentTenantAuthority(employee);

  const isDatevOnlyUser = datevEmployee && !datevEmployee.user;

  const getUserBaseFromDatevEmployee = (): Partial<IUser> | undefined => {
    if (!datevEmployee) return undefined;

    const {
      email,
      name,
    } = datevEmployee;

    const result: Partial<IUser> = {};

    if (!!email) result.mailAddress = email;

    if (!!name) {
      try {
        if (name.includes(",")) {
          const [lastName, firstName] = name.split(",").map(n => n.trim());
          result.firstName = firstName;
          result.lastName = lastName;
        }
        else result.firstName = name;
      }
      catch { }
    }

    return result;
  }

  const email = employee?.mailAddress ?? datevEmployee?.email;

  return (
    <TableRow>
      <TableCell>
        {
          employee
            ? (
              <div className="d-flex flex-column align-items-start">
                <span>{getName(employee)}</span>
                <UserRoleIndicator user={employee} />
              </div>
            )
            : (
              datevEmployee
                ? datevEmployee.name
                : <span>-</span>
            )
        }
      </TableCell>
      <TableCell >
        {
          datevEmployee
            ? (
              (datevEmployee.isDuplicate && datevEmployee.duplicateReason === DatevDuplicateReason.Mail) && (
                <Tooltip
                  tooltip="Die E-Mail dieses Mitarbeiters ist auch bei anderen Mitarbeitern vorhanden. Dies beeinträchtigt die Funktion des Systems und führt beispielsweise dazu, dass dieser Mitarbeiter nicht korrekt aktualisiert wird. Bitte stellen Sie sicher, dass diese E-Mail entweder nicht für das System verwendet werden soll oder die E-Mail nur bei einem Mitarbeiter vorkommt."
                  tooltipTitle="Doppelte E-Mail"
                >
                  <Pill
                    icon="info-circle"
                    color="#F00"
                  >
                    Doppelte E-Mail
                  </Pill>
                </Tooltip>
              )
            )
            : null
        }
        <span>{email ?? "-"}</span>
      </TableCell>
      <TableCell>
        {
          employee
            ? <CheckmarkIcon falseLabel="Nicht verifiziert" trueLabel="Verifiziert" value={employee.isVerified} />
            : (
              datevEmployee
                ? <Pill color={datevEmployee.status === DatevStatus.Active ? "#F0F0F0" : "#F0F0F0"}>{datevEmployee.status === DatevStatus.Active ? "Aktiv in DATEV" : "Inaktiv"} </Pill>
                : <span>-</span>
            )
        }
      </TableCell>
      <TableCell>
        <Pill color={!!datevEmployee ? "#A3FFA3" : "#FFA3A3"}><span className="text-nowrap">{!!datevEmployee ? "Mit DATEV verknüpft" : "Nicht verknüpft"}</span></Pill>
      </TableCell>
      <TableCell value={!!authority && !!authority.userAuthority && !!authority.userAuthority.isTenantAdmin} labelOnFalse="Kein Admin-Zugriff" labelOnTrue={`${getLabel(Label.TenantName)}-Admin`} />
      <TableCell date={employee ? employee.createdAt : (datevEmployee ? datevEmployee.entry_date : undefined)} />
      <TableCell fillAvailableSpace justify="end" >
        <Flex>
          {
            <ManageEmployeeAbsenceButton user={employee} />
          }
          {
            employee
              ? <UserUpdateForm user={employee} />
              : (
                isDatevOnlyUser && datevEmployee && (
                  <UserUpdateForm
                    text="In Nutzer umwandeln"
                    title="Umwandlung in Nutzer"
                    creationBase={getUserBaseFromDatevEmployee()}
                  />
                )
              )
          }
        </Flex>
      </TableCell>
    </TableRow>
  )
}