import React from "react";
import { AppColor } from "../../../../app/AppStyles";
import { generateClassName, generateStyle } from "../../../../hooks/useAttributes";
import useTicketActivityUtil from "../../../../hooks/useTicketActivityUtil";
import useTiggySocket from "../../../../hooks/useTiggiSocket";
import useUserUtil from "../../../../hooks/useUserUtil";
import { useTenant } from "../../../../state/api/tenant/useTenant";
import { useTicketClientContact } from "../../../../state/api/tickets/assignments/useTicketClientContact";
import { useTicketActivity } from "../../../../state/api/tickets/useTicketActivity";
import { useUser } from "../../../../state/api/user/useUser";
import { useUsers } from "../../../../state/api/user/useUsers";
import { ITicket } from "../../../../types/ApiTypes";
import { ITicketActivity } from "../../../../types/ticketActivity.schema";
import { ITicketMessage } from "../../../../types/ticketMessage.schema";
import { formatDateTiggiStyle } from "../../../../util/formatter";
import { getId, getModel } from "../../../../util/mongoUtil";
import { getTextColorFromBackground } from "../../../../util/util";
import Card from "../../../card/Card";
import ClientTypeBanner from "../../../clients/ClientTypeBanner";
import Flex from "../../../container/Flex";
import Divider from "../../../menu/sidebar/Divider";
import Pill from "../../../pills/Pill";
import TeamBanner from "../../../teams/TeamBanner";
import Typography from "../../../text/Typography";
import { default as RoleBanner, default as UserRoleBanner } from "../../../user/RoleBanner";
import TicketLink from "../../TicketLink";
import "./TicketChatMessage.css";
import TicketChatMessageAttachments from "./TicketChatMessageAttachments";
import TicketMessageHistory from "./TicketMessageHistory";
import TicketMessageInfoDialog from "./TicketMessageInfoDialog";
import TicketMessageReadIndicator from "./TicketMessageReadIndicator";
import TicketMessageText from "./TicketMessageText";
import ServerActionStatusIndicator from "../../../serverAction/ServerActionStatusIndicator";
import { useTicketMessage } from "../../../../state/api/tickets/messages/useTicketMessage";
import { ServerActionStatus } from "../../../../types/serverAction.schema";
import useApi from "../../../../hooks/useApi";
import { ticketMessageResendMail } from "../../../../api/Api";
import Icon from "../../../icons/Icon";

interface ITicketChatMessageProps {
  message: ITicketMessage,
  ticket: ITicket,
  colorMap: { [userId: string]: string }
}

export default function TicketChatMessage({ ticket, message, colorMap }: ITicketChatMessageProps) {

  const { ticketClientContact } = useTicketClientContact(ticket);
  const { tenant } = useTenant();

  const callApi = useApi();

  const {
    reloadMessage
  } = useTicketMessage(message._id, { enabled: false });

  const {
    usersById
  } = useUsers();

  const {
    getName,
    getCurrentTenantRole
  } = useUserUtil();

  const {
    senderMailAddress,
  } = message;

  const {
    sendRead
  } = useTiggySocket();



  const { user } = useUser();

  React.useEffect(() => {
    if (isOwn) return;
    if (message && message.isRead) return;
    sendRead(ticket._id, message._id);
    if (message.isRead) return;
  }, [message, ticket]);


  const isMentioned = () => {
    if (!message.mentions || !message.mentions.length) return false;
    if (!user) return false;
    return message.mentions.includes(user._id);
  }

  const sentBy = getModel(message.sentBy, usersById);

  const isOwn = getId(message.sentBy) === getId(user);
  const role = getCurrentTenantRole(sentBy);

  const className = generateClassName("ticket-chat-message w-100 d-flex flex-column position-relative", {
    value: isOwn,
    onTrue: "ticket-chat-message-own align-self-end",
    standard: "ticket-chat-message-other align-self-start"
  });

  const style = generateStyle({
    name: "color",
    value: isOwn ? getTextColorFromBackground("primary") : undefined
  });

  const cardColor: AppColor = message?.status === ServerActionStatus.Failed ? "error" : isMentioned() ? "bright" : (isOwn ? "primary" : ((role && !role.isClient) ? "muted" : "bright"));

  if (!message?.content) return null;

  if (message.isSystemMessage) return <SystemMessage ticket={ticket} message={message} />;

  return (
    <Card
      strongColor={!isOwn && (!role || role.isClient)}
      noPadding
      color={cardColor}
      wrapperClass={className}
      header={
        <Flex className="w-100">
          <Flex row justify="between" align="center" className="w-100" wrap gap="0">
            {
              isOwn
                ? (
                  <Flex row>
                    {
                      (user?.isSuperAdmin || (role && !role.isClient)) && (
                        <ServerActionStatusIndicator
                          colorError="bright"
                          status={message.status}
                          action={message.action}
                          fetchStatus={reloadMessage}
                          retryAction={async () => await callApi(ticketMessageResendMail(message._id))}
                        />
                      )
                    }
                  </Flex>
                )
                : (
                  <Flex gap={0}>
                    {
                      <Flex row>
                        <RoleBanner hideIcon variant="text" user={message.sentBy} displayNameAsValue />
                        {
                          !!(role && role.isClient) && <Typography color="muted">| {ticketClientContact && ticketClientContact.client ? ticketClientContact.client.name : "Kein Mandant"}</Typography>
                        }
                      </Flex>
                    }
                    <Typography
                      size={message.sentBy ? 12 : 14}
                      bold={!message.sentBy}
                      color={message.sentBy ? undefined : "primary"}
                      basedOnThisBackground={isOwn ? "primary" : undefined}
                    >
                      {
                        (role && !role.isClient && tenant?.name)
                          ? `@${tenant.name}`
                          : senderMailAddress
                      }
                    </Typography>
                  </Flex>
                )
            }
            <Flex row wrap className="ms-auto gap-1">
              {
                message.sourceLink && (
                  <Pill
                    icon="link"
                    text="Quelle"
                    color={isOwn ? "secondary" : "primary"}
                    onClick={() => window.open(message.sourceLink)}
                  />
                )
              }
              <TicketMessageInfoDialog ticketMessage={message} ticket={ticket} />
              {
                isOwn && <TicketMessageReadIndicator message={message} />
              }
              <TicketMessageHistory message={message} />
              <Typography className="ms-auto" basedOnThisBackground={isOwn ? "primary" : undefined} size={14}>{formatDateTiggiStyle(message.createdAt)}</Typography>
            </Flex>
          </Flex>
        </Flex>
      }
    >
      <Flex className="ps-3 pb-2 pt-3 pe-3 w-100" style={style}>
        <TicketMessageText ticketId={ticket._id} message={message} canEdit={isOwn} />
        <TicketChatMessageAttachments message={message} />
        <MentionedUsers message={message} cardColor={cardColor} />
        <CcUsers message={message} cardColor={cardColor} />
      </Flex>
    </Card>
  )
}

function SystemMessage(props: { ticket: ITicket, message: ITicketMessage }) {

  const {
    ticket,
    message
  } = props;

  const {
    loadingTicketActivity,
    ticketActivityById
  } = useTicketActivity(ticket);

  const activity: ITicketActivity | undefined = ticketActivityById?.[message.ticketActivity];

  return (
    <Flex
      fullWidth
      justify="center"
      align="center"
      className="ticket-chat-system-message"
    >
      <Pill
        loading={loadingTicketActivity}
        loadingText={message.content[0].text}
        color="backgroundLighter"
      >
        <Flex gap="2" className="p-1" align="start" row >
          <Flex gap="0">
            <Typography size="9" bold>{formatDateTiggiStyle(message.createdAt)}</Typography>
            <Typography>{message.content[0].text}</Typography>
          </Flex>
          {
            activity && (
              <Flex fullWidth>
                {
                  activity.activityAffectsUser && <RoleBanner user={activity.activityAffectsUser} displayNameAsValue />
                }
                {
                  activity.activityAffectsClient && <ClientTypeBanner client={activity.activityAffectsClient} showClientName />
                }
                {
                  activity.mergedIntoTicket && <TicketLink ticket={activity.mergedIntoTicket} />
                }
                {
                  activity.activityAffectsTeam && <TeamBanner team={activity.activityAffectsTeam} />
                }
              </Flex>
            )
          }
        </Flex>
      </Pill>
    </Flex>
  )
}

function CcUsers({ message, cardColor }: { message: ITicketMessage, cardColor?: AppColor }) {
  if (!message?.cc?.length) return null;

  return (
    <Flex fullWidth>
      <Divider height={1} opacity={0.5} />
      <Typography basedOnThisBackground={cardColor} >CC:</Typography>
      <Flex row fullWidth>
        {
          message.cc.map(m => {
            if (!m.user && !m.mailAddress) return null;
            if (!m.user) return <Typography key={m.mailAddress} basedOnThisBackground={cardColor}>{m.mailAddress}</Typography>
            return <UserRoleBanner key={m.user} user={m.user} displayNameAsValue />
          })
        }
      </Flex>
    </Flex>
  )
}

function MentionedUsers({ message, cardColor }: { message: ITicketMessage, cardColor?: AppColor }) {
  if (!message.mentions?.length) return null;

  return (
    <Flex fullWidth>
      <Divider height={1} opacity={0.5} />
      <Typography basedOnThisBackground={cardColor} >Erwähnt:</Typography>
      <Flex row fullWidth>
        {
          message.mentions.map(m => <UserRoleBanner key={m} user={m} displayNameAsValue />)
        }
      </Flex>
    </Flex>
  )
}