import { Form, Formik } from "formik";
import React from "react";
import { blacklistCreateForTenant, blacklistDeleteForTenant, tenantsUpdate } from "../../../api/Api";
import { ITenantUpdateSettingsRequest } from "../../../api/ApiRequests";
import useApi from "../../../hooks/useApi";
import useLabels, { Label } from "../../../hooks/useLabels";
import useTenantSettings from "../../../hooks/useTenantSettings";
import { useTenantBlacklist } from "../../../state/api/blacklist/useTenantBlacklist";
import { useTenant } from "../../../state/api/tenant/useTenant";
import { useTenants } from "../../../state/api/tenant/useTenants";
import { DocumentClearanceReminderType, ITenant, TenantLogoStyle } from "../../../types/ApiTypes";
import { getId } from "../../../util/mongoUtil";
import BlacklistOverview from "../../blacklist/BlacklistOverview";
import Button from "../../buttons/Button";
import GrantCdpConsent from "../../cdp/GrantCdpConsent";
import Select from "../../comboBox/Select";
import Flex from "../../container/Flex";
import TicketEscalationList from "../../escalation/TicketEscalationList";
import ImageSelect from "../../files/ImageSelect";
import CheckBox from "../../forms/CheckBox";
import ColorPicker from "../../forms/ColorPicker";
import FieldWithLabel from "../../forms/FormikField";
import LoadingSpinner from "../../loader/LoadingSpinner";
import WithPermissions from "../../permissions/WithPermissions";
import SalutationSelect from "../../salutations/SalutationSelect";
import SignatureUpdateForm from "../../signatures/SignatureUpdateForm";
import TabSwitcher from "../../tabswitcher/TabSwitcher";
import Typography from "../../text/Typography";
import UserSelect from "../../user/UserSelect";
import OpeningHoursUpdateForm from "../openinghours/OpeningHoursUpdateForm";
import TenantAbortDeleteButton from "../TenantAbortDeleteButton";
import TenantDeleteButton from "../TenantDeleteButton";
import TicketMailBehaviorSelect from "./TicketMailBehaviorSelect";

export enum TenantSettingsPage {
  General = "general",
  Labels = "labels",
  OpeningHours = "openinghours",
  TicketEscalation = "escalation",
  DocumentReminder = "reminders",
  Mail = "mail",
  Signature = "signature",
  Tickets = "tickets",
  Cdp = "cdp",
  DangerZone = "dangerzone"
}

export enum TenantMailSettingsTab {
  Settings = "settings",
  Blacklist = "blacklist"
}

export interface ITenantUpdateFormValues extends ITenantUpdateSettingsRequest {
  name: string
}

export default function TenantUpdateForm({ tenant, afterSubmit, canDelete = true }: { tenant: ITenant, canDelete?: boolean, afterSubmit?: () => void }) {

  const [page, setPage] = React.useState<TenantSettingsPage>(TenantSettingsPage.General);
  const [mailTab, setMailTab] = React.useState<TenantMailSettingsTab>(TenantMailSettingsTab.Settings);

  const {
    titles
  } = useTenantSettings(true);

  const getLabel = useLabels();
  const { reloadTenants } = useTenants();
  const { tenant: currentTenant, reloadTenant } = useTenant();

  const settings = useTenantSettings(false, tenant, currentTenant ? getId(tenant) === getId(currentTenant) : false);
  const callApi = useApi();

  const {
    loadingTenantBlacklist,
    reloadTenantBlacklist,
    tenantBlacklistByType
  } = useTenantBlacklist({ enabled: page === TenantSettingsPage.Mail });

  const getContent = () => {
    switch (page) {
      case TenantSettingsPage.Cdp: return <GrantCdpConsent />

      case TenantSettingsPage.Tickets: return (
        <Formik
          enableReinitialize
          initialValues={{
            ...settings,
            name: tenant.name,
            tenantId: tenant._id,
          } as ITenantUpdateFormValues}
          onSubmit={async (values, actions) => {
            const result = await callApi(tenantsUpdate(values));

            if (!result) return;

            if (afterSubmit) afterSubmit();

            await reloadTenants();

            if (!currentTenant) return;

            if (values.tenantId === currentTenant._id) await reloadTenant();

          }}
        >
          {
            formik => (
              <Form className="w-100">
                <Flex fullWidth gap={4}>
                  <Flex fullWidth gap={2}>
                    <Typography bold size="16">Statistiken</Typography>
                    <FieldWithLabel className="w-100" label="Vorgabe für Minuten bis zur ersten Nachricht" min="15" type="number" placeholder="Vorgabe für Zeit bis zur ersten Nachricht" name="tickets.maxMinutesToFirstResponse" />
                  </Flex>
                  <Flex fullWidth gap={2}>
                    <Typography bold size="16">Eskalation</Typography>
                    <span style={{ fontSize: "0.8em" }}>Wählen Sie hier den Nutzer, an welchen im Fall der Fälle standardmäßig eskaliert wird.</span>
                    <UserSelect
                      placeholder="Vorgesetzten auswählen..."
                      displayed="employees"
                      label="Eskalationsvorgesetzter (Kanzlei)"
                      onChange={(val) => formik.setFieldValue("tickets.escalationSupervisor", val?._id ?? "")}
                      value={formik.values.tickets.escalationSupervisor}
                    />
                  </Flex>
                  <Button disabled={!formik.dirty} loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />
                </Flex>
              </Form>
            )
          }
        </Formik>
      )
      case TenantSettingsPage.Labels: return (
        <Formik
          enableReinitialize
          initialValues={{
            ...settings,
            name: tenant.name,
            tenantId: tenant._id,
          } as ITenantUpdateFormValues}
          onSubmit={async (values, actions) => {
            const result = await callApi(tenantsUpdate(values));
            if (!result) return;
            if (afterSubmit) afterSubmit();
            await reloadTenants();
            if (!currentTenant) return;
            if (values.tenantId === currentTenant._id) await reloadTenant();
          }}
        >
          {
            (formik) => {

              return (
                <Form className="w-100 h-100 d-flex flex-column gap-4 text-start">
                  <h6 className="fw-bold">Titel und Bezeichnungen</h6>
                  <FieldWithLabel name="titles.siteHeader" label="Menütitel" />
                  <Flex row fullWidth>
                    <FieldWithLabel name="titles.tenantWord" label={`Bezeichnung für ${getLabel(Label.TenantName)}`} placeholder="z.B. Kanzlei, Beratungsgesellschaft..." />
                    <FieldWithLabel name="titles.tenantsWord" label="Mehrzahl" placeholder="z.B. Kanzlei, Beratungsgesellschaft..." />
                  </Flex>
                  <FieldWithLabel name="titles.documentClearanceWord" label="Bezeichnung für Belegabgabe" placeholder="z.B. Buchhaltungsfreigabe, Belegübermittlung..." />
                  <FieldWithLabel name="titles.teamMailAccountsTitle" label="Bezeichnung für Team-Postfächer" placeholder="z.B. Teams..." />
                  <Button disabled={!formik.dirty} loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />
                </Form>
              )
            }
          }
        </Formik>
      );

      case TenantSettingsPage.General: return (
        <Formik
          enableReinitialize
          initialValues={{
            ...settings,
            name: tenant.name,
            tenantId: tenant._id,
          } as ITenantUpdateFormValues}
          onSubmit={async (values, actions) => {
            const result = await callApi(tenantsUpdate(values));

            if (!result) return;

            if (afterSubmit) afterSubmit();

            await reloadTenants();

            if (!currentTenant) return;

            if (values.tenantId === currentTenant._id) await reloadTenant();

          }}
        >
          {
            (formik) => {
              return (
                <Form className="w-100 h-100 d-flex flex-column gap-4 text-start">
                  <Flex fullWidth>
                    <Typography bold size="16">Anzeige</Typography>
                    <FieldWithLabel className="w-100" label="Name" name="name" />
                    <Flex gap="1" fullWidth>
                      <Typography color="primary">Logo</Typography>
                      <ImageSelect image={formik.values.theme ? formik.values.theme.logo : undefined} saveImage={(img) => formik.setFieldValue("theme.logo", img)} />
                    </Flex>
                    <Flex fullWidth>
                      <Typography color="primary">Logo-Stil</Typography>
                      <Select
                        value={formik.values.ui?.sidebar?.logoStyle}
                        values={[
                          { data: TenantLogoStyle.LogoAndText, label: "Logo und Text" },
                          { data: TenantLogoStyle.LogoOnly, label: "Nur Logo" },
                          { data: TenantLogoStyle.TextOnly, label: "Nur Text" }
                        ]}
                        onChange={(val) => formik.setFieldValue("ui.sidebar.logoStyle", val)}
                      />
                    </Flex>
                  </Flex>
                  <Flex fullWidth>
                    <Typography bold size="16">Links</Typography>
                    <FieldWithLabel className="w-100" name="links.privacyPolicy" label="Datenschutzerklärung" />
                    <FieldWithLabel className="w-100" name="links.imprint" label="Impressum" />
                  </Flex>
                  <Flex fullWidth>
                    <Typography bold size="16">Theme</Typography>
                    <Flex gap="1">
                      <Typography color="primary">Theme-Farbe</Typography>
                      <Flex gap="5" row>
                        <ColorPicker name="theme.primaryColor" />
                        <Button text="Farbdemo" hexColor={formik.values.theme?.primaryColor ?? "#000000"} />
                        <LoadingSpinner hexColor={formik.values.theme?.primaryColor ?? "#000000"} />
                        <Typography color={formik.values.theme?.primaryColor ?? "#000000"} size="16">Farbdemo</Typography>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Button disabled={!formik.dirty} loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />
                </Form>
              )
            }
          }
        </Formik>
      );
      case TenantSettingsPage.DocumentReminder: return (
        <Formik
          enableReinitialize
          initialValues={{
            ...settings,
            name: tenant.name,
            tenantId: tenant._id,
          } as ITenantUpdateFormValues}
          onSubmit={async (values, actions) => {
            const result = await callApi(tenantsUpdate(values));

            if (!result) return;

            if (afterSubmit) afterSubmit();

            await reloadTenants();

            if (!currentTenant) return;

            if (values.tenantId === currentTenant._id) await reloadTenant();

          }}
        >
          {
            (formik) => {
              return (
                <Form className="w-100 h-100 d-flex flex-column gap-4 text-start">
                  <div className="d-flex flex-column w-100 gap-2">
                    <h6 className="fw-bold">{titles?.documentClearanceWord || "Belegabgabe"}</h6>
                    <Flex fullWidth gap="0">
                      <CheckBox
                        name="options.documentClearanace.setAsClearedOnLinkClick"
                        label="Bereits bei Link-Klick in E-Mail als erledigt markieren"
                      />
                      <Typography size="9" color="primary">Bewirkt, dass bereits bei Klick auf den Link in der an den Mandanten gesendeten E-Mail die Freigabe erteilt wird und der Mandant keinen Button mehr betätigen muss.</Typography>
                    </Flex>
                    <FieldWithLabel label="Erinnerung senden am" noMargin type="number" className="w-100" name="options.documentClearance.defaultReminderDayOfMonth" min="1" max="28" step="1" />
                    <FieldWithLabel label="Anzahl der Erinnerungsmails" noMargin type="number" className="w-100" name="options.documentClearance.defaultReminderCount" min="0" max="5" step="1" />
                    <FieldWithLabel label="Tage zwischen den Erinnerungen" noMargin type="number" className="w-100" name="options.documentClearance.defaultReminderInterval" min="1" max="14" step="1" />
                    <Select
                      label="Erinnerungstyp"
                      values={[
                        { data: DocumentClearanceReminderType.LinkAndText, label: "Link und Text" },
                        { data: DocumentClearanceReminderType.TextOnly, label: "Nur Text" },
                      ]}
                      value={formik.values.options.documentClearance.reminderType}
                      onChange={o => formik.setFieldValue("options.documentClearance.reminderType", o)}
                    />
                  </div>
                  <Button disabled={!formik.dirty} loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />
                </Form>
              )
            }
          }
        </Formik>
      );
      case TenantSettingsPage.Mail: return (

        <Flex fullWidth>
          <TabSwitcher
            tabQueryParamKey="show"
            saveActiveTab={t => setMailTab(t as TenantMailSettingsTab)}
            tabs={[
              { data: TenantMailSettingsTab.Settings, label: "Einstellungen" },
              { data: TenantMailSettingsTab.Blacklist, label: "Blacklist" }
            ]}
            size="tiny"
          />
          {
            mailTab === TenantMailSettingsTab.Settings
              ? (

                <Formik
                  enableReinitialize
                  initialValues={{
                    ...settings,
                    name: tenant.name,
                    tenantId: tenant._id,
                  } as ITenantUpdateFormValues}
                  onSubmit={async (values, actions) => {
                    const result = await callApi(tenantsUpdate(values));

                    if (!result) return;

                    if (afterSubmit) afterSubmit();

                    await reloadTenants();

                    if (!currentTenant) return;

                    if (values.tenantId === currentTenant._id) await reloadTenant();

                  }}
                >
                  {
                    formik => (
                      <Form className="w-100">
                        <Flex className="w-100" gap={3}>
                          <Typography bold size="16">Mandantenkommunikation</Typography>
                          <SalutationSelect onChange={s => formik.setFieldValue("mails.defaultSalutation", s)} value={formik.values.mails.defaultSalutation} />
                          <Typography bold>Tickets</Typography>
                          <Flex fullWidth>
                            <Typography size="12" bold>Mailversand</Typography>
                            <CheckBox name="options.sendTicketMailsToClients" label="Ticket-Mails an Mandanten senden" />
                            <Flex gap={1}>
                              <CheckBox label="Eröffnungs-Mail für ein Ticket senden" name="mails.sendTicketOpenedMail" />
                              <span style={{ fontSize: "0.8em" }}>Mit dieser Option wird nach der Eröffnung eines Tickets eine Mail versandt.</span>
                            </Flex>
                            <Flex gap={1}>
                              <CheckBox label="Keine Ticketfehlermails versenden" name="mails.dontSendTicketErrorMails" />
                              <span style={{ fontSize: "0.8em" }}>Diese Option ankreuzen, um keine Fehlermails bei nicht eröffneten Tickets zu versenden.</span>
                            </Flex>
                            {
                              !formik.values.mails.dontSendTicketErrorMails && (
                                <Flex gap={1}>
                                  <CheckBox label="Keine Ticketfehlermails über persönliche Mailkonten versenden" name="mails.dontSendTicketErrorMailsWithPersonalAccount" />
                                  <span style={{ fontSize: "0.8em" }}>Diese Option ankreuzen, um keine Fehlermails bei nicht eröffneten Tickets über persönliche Mailkonten zu versenden.</span>
                                </Flex>
                              )
                            }
                          </Flex>
                          <Flex fullWidth>
                            <Typography bold size="12">Mailerhalt</Typography>
                            <TicketMailBehaviorSelect
                              onChange={s => formik.setFieldValue("mails.moveToTicketMailFolder", s)}
                              value={formik.values.mails.moveToTicketMailFolder}
                              label="Ticket-Mail in Ordner 'Tickets' verschieben"
                            />
                            <TicketMailBehaviorSelect
                              onChange={s => formik.setFieldValue("mails.setTicketMailRead", s)}
                              allowMultiple
                              value={formik.values.mails.setTicketMailRead}
                              label="Ticket-Mail als gelesen markieren"
                            />
                            <TicketMailBehaviorSelect
                              onChange={s => formik.setFieldValue("mails.deleteTicketMail", s)}
                              allowMultiple
                              value={formik.values.mails.deleteTicketMail}
                              label="Ticket-Mail löschen"
                            />
                          </Flex>
                          <Flex fullWidth>
                            <Typography bold size="12">Absender</Typography>
                            <Flex gap={1}>
                              <CheckBox label="Persönliche Mailkonten bevorzugt für das Senden von Ticketmails verwenden" name="mails.sendMailsPreferrablyWithPersonalAccounts" />
                              <span style={{ fontSize: "0.8em" }}>Diese Option ankreuzen, um bei Ticketaktivitäten die persönliche Mail des Bearbeiters zum Versenden der Mail zu verwenden. Dies kann den Mandantenbezug erhöhen.</span>
                            </Flex>
                          </Flex>
                          <Flex fullWidth>
                            <Typography bold size="12">Anhänge</Typography>
                            <Flex fullWidth gap={2}>
                              <CheckBox label="Nachrichten-Historie mit jeder Mail mitsenden" name="tickets.sendMessageHistoryWithEveryTicketMail" />
                              <span style={{ fontSize: "0.8em" }}>Mit dieser Option wird die Nachrichten-Historie für ein Ticket mit jeder Benachrichtigung für dieses Ticket mitgesandt.</span>
                            </Flex>
                            <Flex gap={1}>
                              <CheckBox label="Nutzer erhalten Anhänge von Ticketnachrichten per Mail" name="mails.usersReceiveUnsafeAttachmentsPerMailDefault" />
                              <span style={{ fontSize: "0.8em" }}>Mit dieser Option wird der von jedem Nutzer eingestellte Wert überschrieben und der Nutzer muss seine Präferenz gegebenenfalls neu einstellen.</span>
                            </Flex>
                          </Flex>
                          <Button disabled={!formik.dirty} loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />
                        </Flex>
                      </Form>
                    )
                  }

                </Formik>
              )
              : (
                <Flex fullWidth>
                  <Typography bold size="16">Blacklist</Typography>
                  <BlacklistOverview
                    loading={loadingTenantBlacklist}
                    reloadEntries={reloadTenantBlacklist}
                    createBlacklistEntry={async (entry) => await blacklistCreateForTenant(entry)}
                    deleteBlacklistEntry={async (id) => await blacklistDeleteForTenant(id)}
                    entries={tenantBlacklistByType}
                  />
                </Flex>
              )
          }
        </Flex>
      );
      case TenantSettingsPage.TicketEscalation: return <TicketEscalationList />
      case TenantSettingsPage.OpeningHours: return <OpeningHoursUpdateForm />;
      case TenantSettingsPage.DangerZone: return (
        <Flex justify="start">
          {
            tenant && canDelete && (
              <TenantDeleteButton tenant={tenant} afterDelete={() => {
                if (afterSubmit) afterSubmit();
                reloadTenants();
              }} />
            )
          }
          <TenantAbortDeleteButton tenant={tenant} afterSubmit={() => {
            if (afterSubmit) afterSubmit();
            reloadTenants();
          }} />
        </Flex>
      );

      case TenantSettingsPage.Signature: return <SignatureUpdateForm />
    }
  }

  return (
    <WithPermissions permissions={["tenant.own.update"]}>
      <Flex gap={3} className="w-100">
        <TabSwitcher
          className="w-100"
          size="small"
          tabQueryParamKey="settings"
          tabs={[
            {
              data: TenantSettingsPage.General,
              label: "Allgemein"
            },
            {
              data: TenantSettingsPage.Labels,
              label: "Bezeichnungen"
            },
            {
              data: TenantSettingsPage.Tickets,
              label: "Tickets",
            },
            {
              data: TenantSettingsPage.OpeningHours,
              label: "Öffnungszeiten"
            },
            {
              data: TenantSettingsPage.DocumentReminder,
              label: titles?.documentClearanceWord || "Belegabgabe"
            },
            {
              data: TenantSettingsPage.TicketEscalation,
              label: "Eskalations-Workflow"
            },
            {
              data: TenantSettingsPage.Mail,
              label: "Mails"
            },
            {
              data: TenantSettingsPage.Signature,
              label: "Signatur",
            },
            {
              data: TenantSettingsPage.Cdp,
              label: "Datenschutz und Privatsphäre"
            },
            {
              data: TenantSettingsPage.DangerZone,
              label: "Gefahrenzone",
              hidden: !canDelete,
              backColor: "red",
              foreColor: "red"
            }
          ]}
          saveActiveTab={t => setPage(t as TenantSettingsPage)}
        />
        {
          getContent()
        }
      </Flex>
    </WithPermissions>
  )
}