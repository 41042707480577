import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useTenant } from "../../state/api/tenant/useTenant";

export default function Logo() {

  const { tenant } = useTenant();

  return (
    <div className="text-center d-flex flex-column" >
      <h1 className="fw-bold m-0 p-0 display-5">
        {
          tenant?.name || "Ticketsystem"
        }
      </h1>
    </div>
  )
}