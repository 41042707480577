import React from "react";
import Flex from "../../../components/container/Flex";
import Page from "../../../components/container/Page";
import Logo from "../../../components/logo/Logo";
import IElementProps from "../../../types/element.types";
import "./LogInPage.css";
import Typography from "../../../components/text/Typography";
import LoadingSpinner from "../../../components/loader/LoadingSpinner";

export interface ILogInPageProps extends IElementProps {
  description?: string,
  loading?: boolean,
  loadingText?: string
}

export default function LogInPage(props: ILogInPageProps) {

  const {
    children,
    loading,
    description,
    loadingText
  } = props;

  return (
    <Page fullscreen centered colored>
      <Logo />
      <Flex className="log-in-form" gap={3} align="center" justify="center">
        <Flex fullWidth gap="1" align="center" justify="center">
          <Typography basedOnThisBackground="primary" size={24} bold >Willkommen zurück!</Typography>
          {description && <Typography basedOnThisBackground="primary" bold>{description}</Typography>}
        </Flex>
        {
          loading
            ? <LoadingSpinner size={loadingText ? 24 : 48} invertThisColor="primary" text={loadingText} />
            : children
        }
      </Flex>
    </Page>
  )
}