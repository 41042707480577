import React from "react";
import Flex from "../../../container/Flex";
import TicketChatMessageAttachment from "./TicketChatMessageAttachment";
import useApi from "../../../../hooks/useApi";
import Button from "../../../buttons/Button";
import { ticketMessagesDownloadAttachments } from "../../../../api/Api";
import useDownloads from "../../../../hooks/useDownloads";
import { ITicketMessage } from "../../../../types/ticketMessage.schema";
import CheckBox from "../../../forms/CheckBox";
import Pill from "../../../pills/Pill";

export default function TicketChatMessageAttachments({ message }: { message: ITicketMessage }) {

  const [smallOnesVisible, setSmallOnesVisible] = React.useState<boolean>(false);

  const callApi = useApi();

  const {
    downloadFile
  } = useDownloads();

  if (!message.attachments?.length) return null;

  const bigOnes = message.attachments.filter(a => a.size > 102400);
  const difference = message.attachments.length - bigOnes.length;

  const downloadAll = async () => {
    const res = await callApi(ticketMessagesDownloadAttachments({ attachments: message.attachments, id: message._id }));
    if (res && res.success) downloadFile(res.data, res.data.content);
  }


  return (
    <Flex fullWidth>
      <Flex row justify="between" wrap align="start" className="w-100" gap="0">
        {
          difference <= 0
            ? <strong>Anhänge</strong>
            : (
              <Button
                text={smallOnesVisible ? "Alle Anänge" : `Wichtige Anhänge (${bigOnes.length} / ${message.attachments.length})`}
                size="small"
                variant="text"
                onClick={() => setSmallOnesVisible(!smallOnesVisible)}
              />
            )
        }
        <Button size="small" icon="download" className="align-self-end ms-auto" tooltip="Alle Anhänge speichern" variant="text" onClick={downloadAll} />
      </Flex>
      <Flex row gap="2" fullWidth wrap className="position-relative">
        {
          (smallOnesVisible ? message.attachments : bigOnes).map(a => <TicketChatMessageAttachment key={a.googleCloudFileId} attachment={a} message={message} />)
        }
      </Flex>
    </Flex>
  )
}