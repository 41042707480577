import React, { ReactElement } from "react";
import { IModalFormBase } from "./ModalForm";

interface IFastModalFormProps {
  button: ReactElement,
  dialog: ReactElement<IModalFormBase>
}

export default function FastModalForm(props: IFastModalFormProps) {

  const {
    button,
    dialog
  } = props;

  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <>
      {
        button && React.cloneElement(button, { onClick: () => setVisible(true) })
      }
      {
        visible && React.cloneElement<IModalFormBase>(dialog, { visible, setVisible, isFast: true })
      }
    </>
  )
}